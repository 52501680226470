import axios from "axios";
import { encryptData, decryptData } from '../../pages/cn/components/common/Utils';


var common_axios = axios.create({});

common_axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("authToken");
    const cnId = localStorage.getItem("cnId");
    if (token) {
      config.headers["Authorization"] = token;
    }

    if (config.method === 'post' || config.method === 'put' || config.method === 'patch') {
      //config.data = { ...config.data, cnId: cnId };

      //let encP = { ...config.data, cnId: cnId };
      let encP = { ...config.data };
      console.log("request of ", config.url, " = ", encP);

      const jsonString = JSON.stringify(encP);
      const encryptedText = encryptData(jsonString);
      const encodedEncryptedText = encodeURIComponent(encryptedText);

      config.data = { 'encParams': encodedEncryptedText };
    } else {
      config.params = { ...config.params };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


common_axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
common_axios.defaults.axios = axios;

common_axios.interceptors.response.use(
  function (response) {
    response.data = JSON.parse(decryptData(response.data));
    console.log("response of ", response.config.url, " = ", response.data);
    return response;
  },
  function (error) {
    return;
    if (axios.isCancel(error)) return true;
    if (error.response.status == 404) {
      window.location.href = '/logout';
    }
    if (error.response.status == 401) {
      window.location.href = '/logout';
    }
    console.log(error.response.status);

  }
);


export default common_axios;