import React, { useState,useEffect } from 'react';
import { LoaderLoader } from '../../utils/CommonLibrary';
import { fetchAllDataCategoryService } from '../../../services/mtp/Service';
import { Modal, Button } from 'react-bootstrap';

function ContentProgramsDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [programName, setProgramName] = useState('');
  const [programDescription, setProgramDescription] = useState('');
  const [descriptionForMember, setDescriptionForMember] = useState('');
  const [submittedData, setSubmittedData] = useState(null);
  const [finalData, setFinalData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [showSelectedMonth, setShowSelectedMonth] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  const [selectedConditionIndex, setSelectedConditionIndex] = useState('');
  const [selectedPathwayIndex, setSelectedPathwayIndex] = useState('');
  const [selectedServiceIndex, setSelectedServiceIndex] = useState('');

  const months = [
    '1 Month', '2 Month', '3 Month', '4 Month', '5 Month', '6 Month', 
    '7 Month', '8 Month', '9 Month', '10 Month', '11 Month', '12 Month'
  ];

  const handleBackClick = () => {
    window.history.back();
  };

  const handleAddCondition = () => {
    setConditions([...conditions, { conditionName: '', conditionDescription: '', pathways: [] }]);
  };

  const handleRemoveCondition = (index) => {
    const updatedConditions = conditions.filter((_, i) => i !== index);
    setConditions(updatedConditions);
  };

  const handleConditionChange = (index, field, value) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][field] = value;
    setConditions(updatedConditions);
  };

  const handleAddPathway = (conditionIndex) => {
    const updatedConditions = [...conditions];
    updatedConditions[conditionIndex].pathways.push({ pathwayName: '', services: [] });
    setConditions(updatedConditions);
  };

  const handleRemovePathway = (conditionIndex, pathwayIndex) => {
    const updatedConditions = [...conditions];
    updatedConditions[conditionIndex].pathways = updatedConditions[conditionIndex].pathways.filter((_, i) => i !== pathwayIndex);
    setConditions(updatedConditions);
  };

  const handlePathwayChange = (conditionIndex, pathwayIndex, value) => {
    const updatedConditions = [...conditions];
    updatedConditions[conditionIndex].pathways[pathwayIndex].pathwayName = value;
    setConditions(updatedConditions);
  };

  const handleAddService = (conditionIndex, pathwayIndex) => {
    const updatedConditions = [...conditions];
    updatedConditions[conditionIndex].pathways[pathwayIndex].services.push({ serviceName: '', selectedMonth: '' });
    setConditions(updatedConditions);
  };

  const handleRemoveService = (conditionIndex, pathwayIndex, serviceIndex) => {
    const updatedConditions = [...conditions];
    updatedConditions[conditionIndex].pathways[pathwayIndex].services = updatedConditions[conditionIndex].pathways[pathwayIndex].services.filter((_, i) => i !== serviceIndex);
    setConditions(updatedConditions);
  };

  const handleServiceChange = (conditionIndex, pathwayIndex, serviceIndex, value) => {
    const updatedConditions = [...conditions];
    updatedConditions[conditionIndex].pathways[pathwayIndex].services[serviceIndex].serviceName = value;
    setConditions(updatedConditions);
  };

  // const handleMonthChange = (conditionIndex, pathwayIndex, serviceIndex, selectedMonth) => {
  //   const updatedConditions = [...conditions];
  //   updatedConditions[conditionIndex].pathways[pathwayIndex].services[serviceIndex].selectedMonth = selectedMonth;
  //   setConditions(updatedConditions);
  //   if (selectedMonth) {
  //   setSelectedMonth(selectedMonth)
  //     setPopupMessage(`You selected ${selectedMonth} month(s) for the service.`);
  //     handleShow();
      
  //   }
  // };


  const closePopup = () => {
    setShowPopup(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

      // Validation logic
    //   if (!programName) {
    //     setPopupMessage("Please enter the program name.");
        
    //     return;
    // }

    // if (!programDescription) {
    //     setPopupMessage("Please enter the program description.");
        
    //     return;
    // }

    // if (!descriptionForMember) {
    //     setPopupMessage("Please enter the description for the member.");
        
    //     return;
    // }

    // if (conditions.length === 0) {
    //     setPopupMessage("Please add at least one condition.");
        
    //     return;
    // }

    // for (let condition of conditions) {
    //     if (!condition.conditionName) {
    //         setPopupMessage("Please enter the condition name for each condition.");
            
    //         return;
    //     }

    //     if (!condition.conditionDescription) {
    //         setPopupMessage("Please enter the condition description for each condition.");
            
    //         return;
    //     }

    //     for (let pathway of condition.pathways) {
    //         if (!pathway.pathwayName) {
    //             setPopupMessage("Please enter the pathway name for each pathway.");
                
    //             return;
    //         }

    //         for (let service of pathway.services) {
    //             if (!service.serviceName) {
    //                 setPopupMessage("Please enter the service name for each service.");
                    
    //                 return;
    //             }

    //             if (!service.selectedMonth) {
    //                 setPopupMessage("Please select a month for each service.");
                    
    //                 return;
    //             }
    //         }
    //     }
    // }
    


    // Collect checkbox values
    const checkboxes = Array.from(document.querySelectorAll('input[type="checkbox"]'))
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.id);
      // Prepare form data
      // const formData = {
      //   programName,
      //   programDescription,
      //   descriptionForMember,
      //   conditions,
      //   selectedServices
      // };

      // console.log("submitData", formData);
      // setSubmittedData(formData);

      const formData = {
        programName,
        programDescription,
        descriptionForMember,
        conditions: conditions.map((condition, conditionIndex) => ({
          conditionName: condition.conditionName,
          conditionDescription: condition.conditionDescription,
          pathways: condition.pathways.map((pathway, pathwayIndex) => ({
            pathwayName: pathway.pathwayName,
          
            services: pathway.services.map((service, serviceIndex) => ({
              selectedMonth: `${showSelectedMonth}`,
              selectedServices: selectedServices[`${conditionIndex}-${pathwayIndex}-${serviceIndex}-${selectedMonth}`] || [],
            })),
          })),
        })),
      };
    
      
      console.log("Submitted Data", JSON.stringify(formData, null, 2));
      setSubmittedData(formData);
    
  };

  //use effect
  useEffect(() => {
    fetchAllData();
}, []);

  //Fetch Data
  const fetchAllData = async () => {
    fetchAllDataCategoryService()
    .then((response) => {   
        setFinalData(response.data.data);
       
    });
    console.log("setFinalData",finalData);
}


const handleMonthChange = (conditionIndex, pathwayIndex, serviceIndex, selectedMonth) => {
  const updatedConditions = [...conditions];
  updatedConditions[conditionIndex].pathways[pathwayIndex].services[serviceIndex].selectedMonth = selectedMonth;
  setConditions(updatedConditions);
  if (selectedMonth) {
      setSelectedMonth(selectedMonth);
      setSelectedConditionIndex(conditionIndex);
      setSelectedPathwayIndex(pathwayIndex);
      setSelectedServiceIndex(serviceIndex);
      setPopupMessage(`You selected ${selectedMonth} month(s) for the service.`);
      handleShow();
  }
};


const [show, setShow] = useState(false);
const handleShow = () => setShow(true);
const handleClose = () => setShow(false);

const handlePopupSubmit = () => {
  setShowSelectedMonth(prevMonths => {
    // Create a new array by filtering out the current month if it exists, and then adding the new month
    const updatedMonths = prevMonths.filter(m => m !== selectedMonth);
    return [...updatedMonths, selectedMonth];
});
  handleClose();
};

const handleServiceCheckboxChange = (conditionIndex, pathwayIndex, serviceIndex, serviceId) => {
  console.log("checkbox",conditionIndex, pathwayIndex, serviceIndex, serviceId)
  const key = `${conditionIndex}-${pathwayIndex}-${serviceIndex}-${selectedMonth}`;

  const updatedConditions = [...conditions];
  updatedConditions[conditionIndex].pathways[pathwayIndex].services[serviceIndex].serviceId=serviceId;
  setConditions(updatedConditions);

  setSelectedServices((prevSelectedServices) => {
      const currentSelected = prevSelectedServices[key] || [];
      if (currentSelected.includes(serviceId)) {
          return {
              ...prevSelectedServices,
              [key]: currentSelected.filter((id) => id !== serviceId),
          };
      } else {
          return {
              ...prevSelectedServices,
              [key]: [...currentSelected, serviceId],
          };
      }
  });
 
};
console.log('currentSelected',selectedServices);
  return (
    <>
      <LoaderLoader isLoading={isLoading} />
      <div className="container-fluid px-0">
        <div className="row align-items-center pb-3 sticky-top-bar">
          <div className="col-md" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackClick}>
              <i className="bi bi-chevron-left me-1"></i> Back
            </button>
            <div className="col">
              <h4 className="mb-0 content-main-title">Add New Program</h4>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className='content-programs p-4'>
            <div className="row align-items-center">
              <div className="col-md-4 mb-3">
                <label htmlFor="nameOfProgram" className="form-label">Name of Program</label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id="nameOfProgram"
                  name="nameOfProgram"
                  placeholder='Enter Name of Program'
                  value={programName}
                  onChange={(e) => setProgramName(e.target.value)}
                />
                
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="descriptionOfProgram" className="form-label">Description of Program</label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id="descriptionOfProgram"
                  name="descriptionOfProgram"
                  placeholder='Enter Description of Program'
                  value={programDescription}
                  onChange={(e) => setProgramDescription(e.target.value)}
                />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="descriptionForTheMember" className="form-label">Description for the Member</label>
                <input
                  type="text"
                  className="form-control input-field-ui"
                  id="descriptionForTheMember"
                  name="descriptionForTheMember"
                  placeholder='Enter Description for the Member'
                  value={descriptionForMember}
                  onChange={(e) => setDescriptionForMember(e.target.value)}
                />
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-12">
                <hr />
              </div>
            </div>

          

            <div className="row align-items-center mb-2">
              <div className="col">
                <h4 className="mb-0 add-new-program-title">Conditions under Program</h4>
              </div>
              <div className='col-auto'>
                <button type="button" className="btn btn-primary program-add-new-btn" onClick={handleAddCondition}>Add Condition</button>
              </div>
            </div>

            {conditions.map((condition, conditionIndex) => (
              <div key={conditionIndex}>
                <div className="row align-items-center">
                  <div className="col-md-6 mb-3">
                    <label htmlFor={`conditionName-${conditionIndex}`} className="form-label">Condition Name</label>
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      id={`conditionName-${conditionIndex}`}
                      name="conditionName"
                      placeholder='Enter Condition Name'
                      value={condition.conditionName}
                      onChange={(e) => handleConditionChange(conditionIndex, 'conditionName', e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor={`conditionDescription-${conditionIndex}`} className="form-label">Condition Description</label>
                    <input
                      type="text"
                      className="form-control input-field-ui"
                      id={`conditionDescription-${conditionIndex}`}
                      name="conditionDescription"
                      placeholder='Enter Condition Description'
                      value={condition.conditionDescription}
                      onChange={(e) => handleConditionChange(conditionIndex, 'conditionDescription', e.target.value)}
                    />
                  </div>
                </div>
               

                <div className="row align-items-center mb-2">
                  <div className="col-md-12">
                    <button type="button" className="btn program-add-new-btn me-2" onClick={() => handleAddPathway(conditionIndex)}>Add Pathway</button>
                    <button type="button" className="btn remove-btn" onClick={() => handleRemoveCondition(conditionIndex)}>Remove Condition</button>
                  </div>
                </div>

                

                {condition.pathways.map((pathway, pathwayIndex) => (
                  <div key={pathwayIndex}>
                    <div className="row align-items-center">
                      <div className="col-md-6 mb-3">
                        <label htmlFor={`pathwayName-${conditionIndex}-${pathwayIndex}`} className="form-label">Pathway Name</label>
                        <input
                          type="text"
                          className="form-control input-field-ui"
                          id={`pathwayName-${conditionIndex}-${pathwayIndex}`}
                          name="pathwayName"
                          placeholder='Enter Pathway Name'
                          value={pathway.pathwayName}
                          onChange={(e) => handlePathwayChange(conditionIndex, pathwayIndex, e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3 mt-4 pt-1">
                        <button type="button" className="btn program-add-new-btn me-2" onClick={() => handleAddService(conditionIndex, pathwayIndex)}>Add Services</button>
                        <button type="button" className="btn remove-btn" onClick={() => handleRemovePathway(conditionIndex, pathwayIndex)}>Remove Pathway</button>
                      </div>
                    </div>
                    {pathway.services.map((service, serviceIndex) => (
                      <div key={serviceIndex}>
                       <div className="row align-items-center mb-3">

                       {/* <div className="row align-items-center mb-3">
                          <div className="col-6">
                              <label htmlFor="selectMonth" className="form-label">Select Month</label>
                              <select className="form-select input-field-ui" id="conditionId" name="selectMonth" aria-label="Select Month"
                              onChange={(e) => handleMonthChange(conditionIndex, pathwayIndex, serviceIndex, e.target.value)}
                              >
                              <option value="">Select Month</option>
                                <option value="1">1 Month</option>
                                <option value="2">2 Month</option>
                            </select>
                          </div>
                        </div> */}

                      <div className="table-responsive pathways-new-table">
                      <table className="table table-striped mb-0 mt-2">
                        <tbody>
                        {months.map((month, index) => (
                          <>
                      
                          <tr key={index}>
                            <td  style={{width:"10%"}}>
                            <label className='main-pathways-title'>{month} {`${conditionIndex}-${pathwayIndex}-${serviceIndex}-${month}`}</label>
                            </td>
                            <td>
                            <div>
                            {selectedServices[`${conditionIndex}-${pathwayIndex}-${serviceIndex}-${month}`]?.length > 0 ? (
                              <>

                            {selectedServices[`${conditionIndex}-${pathwayIndex}-${serviceIndex}-${month}`].map((serName, index) => (
                              <>
                              <span >{serName}</span>
                              </>
                            ))}
                            </>
                            ) : ('')}
                            </div>
                            </td>
                            <td style={{width:"2%"}} className='text-end'>
                            <button className='btn btn-primary program-add-btn' onClick={(e) => handleMonthChange(conditionIndex, pathwayIndex, serviceIndex, month)}><i className="bi bi-plus-circle me-2"></i> Add  Service</button> 
                            </td>
                          </tr>
                        

                      {/* {finalData.map((item, index) => ( 
                            <>
                            
                         
                          <div className="col-12 mt-2" style={{ paddingLeft: "2.7rem" }}>
                          {item.serviceList.map((secondItem, secondindex) => ( 
                            <div className="form-check form-check-inline" key={secondindex}>
                              <input className="form-check-input form-check-input-ui" type="checkbox" value={secondItem.serviceId} name={`checkboxServices-${conditionIndex}-${pathwayIndex}-${serviceIndex}-${secondItem.serviceName}`} id={`checkboxServices-${conditionIndex}-${pathwayIndex}-${secondItem.serviceName}-1`} />
                              <label className="form-check-label mt-1">{secondItem.serviceName}</label>
                            </div>
                          ))}
                           
                          </div>
                          </>
                          ))} */}
                      </>
                ))}

                </tbody>
                      </table>
                    </div>


              </div>


                          <div className="row align-items-center mb-2">
                            <div className="col-md-12">
                              <hr />
                            </div>
                          </div>
                      </div>
                    ))}

                    
                  </div>
                ))}
                {/* <div className="row align-items-center mb-2">
                            <div className="col-md-12">
                              <hr />
                            </div>
                  </div> */}
              </div>
            ))}

            <div className="row align-items-center">
              <div className='col-12 text-center'>
                <button type="submit" className="btn btn-primary program-save-btn me-2">Ok</button>
                <button type="button" className="btn clear-btn" onClick={() => {
                  setProgramName('');
                  setProgramDescription('');
                  setDescriptionForMember('');
                  setConditions([]);
                }}>Clear</button>
              </div>
            </div>
          </div>
        </form>
{/* 
        {submittedData && (
          <div className="submitted-data mt-4">
            <h4>Submitted Data:</h4>
            <pre>{JSON.stringify(submittedData, null, 2)}</pre>
          </div>
        )} */}
        
        <Modal show={show} onHide={handleClose} centered size='lg' id='monthSelectedServices'>
    <Modal.Header closeButton>
        <Modal.Title>Month Selected</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <h5 className='mb-3 form-label'>You selected {selectedMonth} month(s).</h5>
        <div className="row align-items-center mb-3">
            {finalData.map((item, index) => (
                <>
                    <div className="col-12" key={index}>
                    <label className="form-check-label checkbox-program-title">{item.categoryName}</label>
                    </div>
                    <div className="col-12 mt-1 mb-4" style={{ paddingLeft: "1rem" }}>
                        {item.serviceList.map((secondItem, secondindex) => (
                            <div className="form-check form-check-inline" key={secondindex}>
                                <input 
                                    className="form-check-input form-check-input-ui" 
                                    type="checkbox" 
                                    value={secondItem.serviceName}
                                    onChange={() => handleServiceCheckboxChange(selectedConditionIndex, selectedPathwayIndex, selectedServiceIndex, secondItem.serviceName)} 
                                    // checked={selectedServices[`${selectedConditionIndex}-${selectedPathwayIndex}-${selectedServiceIndex}`]?.includes(secondItem.serviceName) || false}
                                />
                                <label className="form-check-label mt-1">{secondItem.serviceName}</label>
                            </div>
                        ))}
                    </div>
                    
                </>
            ))}
        </div>
        <div className='row align-items-center'>
          <div className='col-12 text-center'>
          <button type="button" className='btn btn-primary program-save-btn me-2' onClick={handlePopupSubmit}>
            Submit
        </button>
        <button type="button" className='btn clear-btn' onClick={handleClose}>
            Close
        </button>
          </div>

        </div>

    </Modal.Body>
    
</Modal>
      </div>
      
    </>
    
    
  );
  
}

export default ContentProgramsDetails;
