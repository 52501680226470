const allowedImageTypes = ['jpeg', 'jpg', 'gif', 'png'];
const maxFileSize = 2 * 1024 * 1024; // 2MB

export const validateForm = (formData) => {
  let errors = {};

  // First Name validation
  // const nameRegex = /^[a-zA-Z\s]+$/;
  // if (!formData['firstName'].trim()) {
  //   errors['firstName'] = 'First Name is required';
  // } else if (!nameRegex.test(formData['firstName'])) {
  //   errors['firstName'] = 'First Name should contain only letters and spaces';
  // }

  // Last Name validation
  // if (!formData['lastName'].trim()) {
  //   errors['lastName'] = 'Last Name is required';
  // } else if (!nameRegex.test(formData['lastName'])) {
  //   errors['lastName'] = 'Last Name should contain only letters and spaces';
  // }

  // Email validation
  if (!formData['emailId'].trim()) {
    errors['emailId'] = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(formData['emailId'])) {
    errors['emailId'] = 'Email is invalid';
  } else if (formData['emailId'] !== '' && (formData['firstName'] === '' || formData['lastName'] === '')) {
    errors['emailId'] = 'Please type email id & select from below suggestions';
    errors['firstName'] = 'First Name is required';
    errors['lastName'] = 'Last name is required';
  }

  // Program ID validation
//   if (!formData['programId'].trim()) {
//     errors['programId'] = 'Program is required';
//   }
  if (formData['programId'].length === 0) {
    errors['programId'] = 'Select at least 1 program';
}

  // Role ID validation
  if (!formData['roleId']) {
    errors['roleId'] = 'Role is required';
  }
//   if (formData['roleId'].length === 0) {
//     errors['roleId'] = 'Role is required';
// }
  

  return errors;
};


export const editValidateForm = (formData) => {
    let errors = {};
  
    // User Name validation
    // const usernameRegex = /^[a-zA-Z0-9\s]+$/;
    // if (!formData['userName'].trim()) {
    //   errors['userName'] = 'Username is required';
    // } else if (!usernameRegex.test(formData['userName'])) {
    //   errors['userName'] = 'Username should contain only alphanumeric characters and spaces';
    // }
  
    // First Name validation
    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!formData['firstName'].trim()) {
      errors['firstName'] = 'First Name is required';
    } else if (!nameRegex.test(formData['firstName'])) {
      errors['firstName'] = 'First Name should contain only letters and spaces';
    }
  
    // Last Name validation
    if (!formData['lastName'].trim()) {
      errors['lastName'] = 'Last Name is required';
    } else if (!nameRegex.test(formData['lastName'])) {
      errors['lastName'] = 'Last Name should contain only letters and spaces';
    }
  
    // Email validation
    if (!formData['emailId'].trim()) {
      errors['emailId'] = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData['emailId'])) {
      errors['emailId'] = 'Email is invalid';
    }
  
    // Role ID validation
    if (!formData['roleId']) {
      errors['roleId'] = 'Role is required';
    }


    if (formData['programIdArr'].length === 0) {
        errors['programId'] = 'Select at least 1 program';
    }

    // status validation
    if (!formData['adminStatus'].trim()) {
        errors['adminStatus'] = 'Status is required';
      }

  
    return errors;
  };




  export const validateFormCareAdd = (formData) => {
    let errors = {};
  
    // Role ID validation
    if (formData['mobileNumber'] == '') {
      errors['mobileNumber'] = 'Mobile Number is required';
    } else if (formData['mobileNumber'].length < 8) {
      errors['mobileNumber'] = 'Mobile Number should be of 8 digits';
    }

    if ( formData['imagePath'] == 'https://storage.googleapis.com/ksabupatest/2024/09/24/hjmlr/n03jr8bjba.png' || formData['imagePath'] === '' || formData['imagePath'] === null) {
      errors['imagePath'] = 'Profile pic is required';
    }
    
    if (formData['emailId'] === '' || formData['emailId'] === null) {
      errors['emailId'] = 'Email ID is required';
    } else if (!/\S+@\S+\.\S+/.test(formData['emailId'])) {
      errors['emailId'] = 'Email is invalid';
    } else if (formData['emailId'] !== '' && (formData['firstName'] === '' || formData['lastName'] === '')) {
      errors['emailId'] = 'Please type email id & select from below suggestions';
      errors['firstName'] = 'Name is required';
      errors['lastName'] = 'Last name is required';
    }
    
    if (formData['language'] === '' || formData['language'] === null) {
      errors['language'] = 'Language is required';
    }
    
    if (formData['yourSelf'] === '' || formData['yourSelf'] === null) {
      errors['yourSelf'] = 'This is required';
    }
    
    if (formData['gender'] === '' || formData['gender'] === null) {
      errors['gender'] = 'Gender is required';
    }
  
    return errors;
  };


  export const validateFormServiceAdd = (formData) => {
    let errors = {};
  console.log("validation",formData);
    if(formData['selectedValue'] == ''  || formData['selectedValue'] == undefined){
      errors['selectedValue'] = 'Select Category';
    }else if(formData['serviceName'] == undefined || formData['serviceName'].trim() == ''){
      errors['serviceName'] = 'Add Service Name';
    }else if(formData['serviceRequestType'] == undefined || formData['serviceRequestType'] == ''){
      errors['serviceRequestType'] = 'Service Request Type';
    }
    return errors;  
  };

  export const validateFormCallDispositionAdd = (formData) => {
    let errors = {};
  
    if(formData['dataRating'] == ''  || formData['dataRating'] == undefined){
      errors['dataRating'] = 'select data rating';
    }else if(formData['reason'] == undefined || formData['reason'].trim() == ''){
      errors['reason'] = 'Enter reason';
    }
    return errors;  
  };