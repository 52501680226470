import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Commingsoon } from '../../utils/CommonLibrary';
import { edit_get_new_call_disposition, edit_new_call_disposition, fetch_setting_disposition_agent_list, single_delete_new_call_disposition } from '../../../services/met/Service';
import { validateFormCallDispositionAdd } from '../../utils/validationUtils';


const TableSettingDisposition = ({ initialData,key }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedService, setSelectedService] = useState('All');
    const [passData, setPassData] = useState(initialData);
    const [selectedData, setSelectedData] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const [editId, setEditId] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [dispositionDataList, setDispositionDataList] = useState([]);
    const [editShowModal, setEditShowModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const handleClose = () =>{  setEditShowModal(false);}
    const [deleteShowModal, setDeleteShowModal] = useState(false);
    const handleDeleteClose = () =>{  setDeleteShowModal(false);}
    const [formData, setFormData] = useState({
        dataRating: '',
        reason: '',
    })

    const [errors, setErrors] = useState({
        dataRating: '',
        reason: '',
    })
    

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        //setIsLoading(true);
        try{
            let resp = await fetch_setting_disposition_agent_list();
            if (resp.data.code == 200) {
                setPassData(resp.data.data);
            }else{
                setPassData(resp.data.data);
            }
        } catch (error) {
            console.error(error);
        }finally {
        //setIsLoading(false); // Will be called regardless of success or failure
        }
        
    }

    const columns = [
        {
            name: 'Data Rating',
            selector: row => row.dataRating,
            sortable: true,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: true,
            
        },
        {
            name: 'Action',
            selector: row => row.action,
            cell: row => (
                <div><button onClick={() => setShowEditModalOpen(row.id)} class="btn btn-Edit">Edit</button> / <button onClick={() => setShowDeleteModalOpen(row.id)} class="btn btn-Cancel">Delete</button></div>
            ),
        },
    ];

const data=passData;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedCategory('All');
    };

    const filteredData = data.filter(user => {
        const matchesSearchQuery = 
            (user.dataRating && user.dataRating.toLowerCase().includes(searchQuery.toLowerCase())) || 
            (user.reason && user.reason.toLowerCase().includes(searchQuery.toLowerCase()));
        return matchesSearchQuery;
    });

    const setShowEditModalOpen = async (id) => {
        setEditId(id);
        try{
            let resp = await edit_get_new_call_disposition(id);
            console.log("edit form",resp.data.data[0]);
            if (resp.data.code == 200) {
                setFormData(resp.data.data[0]);
            }else{
                setFormData(resp.data.data);
            }
        } catch (error) {
            console.error(error);
        }finally {
        //setIsLoading(false); // Will be called regardless of success or failure
        }
        setEditShowModal(true);
        
    }
    const setShowDeleteModalOpen = async (id) => {
        setDeleteId(id);
        setDeleteShowModal(true);  
    };
    const submitDeleteDisposition = async(deleteId) =>{

        try{
            let resp = await single_delete_new_call_disposition(deleteId);
            if (resp.data.code == 200) {
                setDeleteMessage(resp.data.data.message);
                setDeleteShowModal(false);
                fetchData();
            }
        } catch (error) {
            console.error(error);
        }finally {
        //setIsLoading(false); // Will be called regardless of success or failure
        }
       
       
    }

    const handleChange = (event) => {
        setFormData((prevData) => ({ ...prevData, ['dataRating']: event.target.value}));
    };


    const editNewDisposition = async (e) => {
        e.preventDefault();
        setErrors('')

        const validationErrors = validateFormCallDispositionAdd(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        }else{
            console.log("formData",formData);
            console.log("editid",editId);
            const combinedData = {
                ...formData,
                id: editId
            };
            edit_new_call_disposition(combinedData)
            .then((response) => {   
                if(response.data.code == 200){
                    setEditShowModal(false);
                   fetchData();
                    
                }
            });
        }
    }

    const onValueChange = (e) =>{    
        setFormData({...formData, [e.target.name]: e.target.value});
    }


    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                            <input
                                type="text"
                                placeholder="Search By Data Rating OR Reason"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    {/* <div className='col-md-6 px-1'>
                        <select
                            value={selectedCategory}
                            onChange={e => setSelectedCategory(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Category--</option>
                            {selectedData.length > 0 && selectedData.map((option) => (
                                <option key={option.categoryId} value={option.categoryName}>
                                    {option.categoryName}
                                </option>
                            ))}
                        </select>
                    </div> */}
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
             <Commingsoon />

             {editShowModal && 
         <div className="modal fade show" id="addNewServiceModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="main-title">Edit Call Disposition</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    <div class="modal-body pt-1 mb-2">
                        <form className='' onSubmit={editNewDisposition}> 
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor='Condition' class="form-label">Data Rating</label>
                                    <span style={{color:'red'}}> *</span>
                                        <select class="form-select input-field-ui" aria-label="Default select example" value={formData['dataRating']} onChange={handleChange}>
                                            <option value="Condition">Select data Rating</option>
                                            <option value="correct">Correct</option>
                                            <option value="incorrect">Incorrect</option>
                                        </select>
                                        {(errors['dataRating'] ) && <div className="text-danger">{errors['dataRating']}</div>}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='Condition' class="form-label">Reason</label>
                                        <span style={{color:'red'}}> *</span>
                                        <textarea
                                        className="form-control input-field-ui"
                                        placeholder="Reason"
                                        rows="3"
                                        name="reason"
                                        onChange={(e) => onValueChange(e)} value={formData['reason']}
                                        />
                                        {errors['reason'] && <div className="text-danger">{errors['reason']}</div>}
                                    </div>
                                    
                                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        >
                                        Save Disposition
                                        </button>
                                        {/* <button
                                        type="button"
                                        className="btn btn-secondary clear-btn"
                                        onClick={handleClose}
                                        >
                                        Cancel
                                        </button> */}
                                    </div>
                                </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
            
                </div> }



                {deleteShowModal && 
         <div className="modal fade show" id="addNewServiceModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                    <button type="button" className="btn-close mb-1"  onClick={handleDeleteClose} ></button>
                        <h6 className="main-title">Are you sure you want to delete this call disposition?</h6>
                    </div>
                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="button"
                                        className="btn btn-primary program-save-btn me-2"
                                        onClick={() => submitDeleteDisposition(deleteId)}
                                        >
                                        Delete
                                        </button>
                                        {/* <button
                                        type="button"
                                        className="btn btn-secondary clear-btn"
                                        onClick={handleDeleteClose}
                                        >
                                        Cancel
                                        </button> */}
                                    </div>
                               
                        
                                    
                        </div>
                        
                    </div>
            
                </div> }
        </>

        
    );
}

export default TableSettingDisposition;
