import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import exporting from 'highcharts/modules/exporting';
import { formatDate } from '../../utils/CommonLibrary';
import { agent_call_conversion_graph } from '../../../services/met/Service';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const CallConversionDispositionBar = ({startDate,endDate,agentId,parentCallback}) => {

  const [loadingCount, setLoadingCount] = useState(0);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    agentDataQualityGraph();    
}, [agentId,startDate,endDate]);

  const agentDataQualityGraph = async () => {
    try {
        let sdate = formatDate(startDate);
        let edate = formatDate(endDate);
        let response = await agent_call_conversion_graph(sdate,edate,agentId);
        if(response.data.code === 200){
           setGraphData(response.data.data[0]);
            setLoadingCount(response.data.data.length)
            parentCallback(response.data.data[0])
        }
    } catch (error) {
        console.log(error);
    } 
}

  // Pie chart data for service completion rate
  const pieData = [
    {
      name: 'Onboarded',
      y: graphData['count_enroll'], // Example percentage, replace with actual data
      color: '#00B9FB' 
    },
    {
      name: 'Not Interested',
      y: graphData['count_reject'], 
      color: '#606CCD' 
    },
    {
      name: 'Onboard Later',
      y: graphData['count_later'], 
      color: '#0B4C94' 
    },
  ];

  const pieOptionsMCI1 = {
    chart: {
      type: 'pie',
      height: 200,
      spacing: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 70,
        beta: 0
      },
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif",
        marginBottom: '10px'
      }
    },
    tooltip: {
      pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 30,
        size: 190,
        dataLabels: {
          enabled: true, // Enable data labels
          format: '{point.percentage:.1f}%',
          distance: 5, // Adjust label position
          alignTo: 'toPlotEdges' // Center the label inside the pie slice
        },
        showInLegend: true
      }
    },
    series: [{
      name: 'Service Status',
      data: pieData
    }],
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: true
    }
  };

  return (
    <>
    {graphData['count_enroll'] > 0  || graphData['count_reject'] > 0 || graphData['count_later'] > 0? (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={pieOptionsMCI1}
      />
    </div>
     ) : (

      <div className="emty-pie">
                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                </div>
     )} 
     </>
  );
};

export default CallConversionDispositionBar;
