import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { Modal } from 'react-bootstrap';
import {
    fetchPathwayServicesMonthCategoryWise
} from "../../components/services/Service";
import './CarePlanChange.css';

const CarePlanChange = ({ pathwayId, showCarePlanModal, handleShowCarePlanModal, handleCloseCarePlanModal }) => {

    const qsParams = useParams();
    const userId = qsParams.memberId;

    // Expandable Table
    const [expandedRow, setExpandedRow] = useState(null);

    const toggleRowExpansion = (index) => {
        setExpandedRow(prevState => (prevState === index ? null : index));
    };

    const [expandedRow1, setExpandedRow1] = useState(null);

    const toggleRowExpansion1 = (index) => {
        setExpandedRow1(prevState => (prevState === index ? null : index));
    };

    const [pathwayServices, setPathwayServices] = useState([]);


    useEffect(() => {
        getPathwayServicesMonthCategoryWise(userId);
    }, [showCarePlanModal]);

    const getPathwayServicesMonthCategoryWise = async (userId) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchPathwayServicesMonthCategoryWise(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setPathwayServices(response.data.data.services);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    return (
        <>
            <Modal id="wpnb1" className='HealthManagement' centered show={showCarePlanModal} onHide={handleCloseCarePlanModal} size="lg" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>{(pathwayServices && pathwayServices.length > 0) ? pathwayServices[0].pathwayName : ""}</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-12 px-0'>
                                <div className='card-task'>
                                    {
                                        pathwayServices.map((mainItem, mainIndex) => {
                                            return <>
                                                <div className="table-responsive" style={{ maxHeight: "380px" }}>
                                                    <table className="table table-striped table-bordered table-expandable mb-0">
                                                        <thead style={{ position: "sticky", top: "0" }}>
                                                            <tr>
                                                                <th scope="col">Services</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                mainItem.services.map((item, index) => {
                                                                    return <>
                                                                        <tr style={{ verticalAlign: 'middle' }}>
                                                                            <td className='bg-td-green hand' onClick={() => toggleRowExpansion1(index)}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/43t1m/vat93u8oj1.png" style={{ height: "20px" }} className='me-2' alt="" />{item.month} Month</td>
                                                                        </tr>
                                                                        {
                                                                            <tr>
                                                                                <td colSpan="3" className='pathways-table-expandable-td-bg p-0'>
                                                                                    <div style={{ width: "98%" }}>
                                                                                        <div className='row pathways-table-expandable'>
                                                                                            <div className="col-12 pe-0">
                                                                                                <div className="table-responsive pathways-new-table" style={{ borderRadius: "0" }}>
                                                                                                    {
                                                                                                        item.categories.map((catItem, catIndex) => {
                                                                                                            return <>
                                                                                                                <table className="table table-striped mb-0 p-0">
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th scope="col"><h5 className="main-pathways-title">{catItem.categoryName}</h5></th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                {
                                                                                                                                    catItem.services.map((msItem, msIndex) => {
                                                                                                                                        return <>
                                                                                                                                            <span>{msItem.serviceName}</span>
                                                                                                                                        </>
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </>
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                    </>
                                                                })
                                                            }
                                                        </tbody>


                                                    </table>
                                                </div>
                                            </>
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 
                    <div className='container-fluid mt-3'>
                        {pathwayId == '1' && 
                            <div className='row'>
                                <div className='col-md-12 px-0'>
                                    <h1 className='carePlanChangeTitle'>Well Controlled</h1>
                                </div>
                                <div className="col-md-12 mt-2 px-0">
                                    <div className="table-responsive pathways-new-table">
                                        <table className="table table-striped mb-0 mt-2">
                                            <tbody>    
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">1st Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span>Comprehensive Medical Evaluation ( History &amp; Physical Examination)</span>
                                                        <span>Dental Examination</span>
                                                        <span>Albumin/Creatinin Ratio</span>
                                                        <span>Eye Examination</span>
                                                        <span>Foot Examination</span>
                                                        <span>HBA1c</span>
                                                        <span>Lipid Profile</span>
                                                        <span>Medical Nutritional Therapy</span>
                                                        <span>Psychological Assessment</span>
                                                        <span>Serum Creatinin</span>
                                                        <span>Smoking Cessation</span>
                                                        <span>Vaccine - Hepatitis B</span>
                                                        <span>Vaccine - Influnza</span>
                                                        <span>Vital Signs &nbsp;&amp; BMI Measurements</span>
                                                        <span>Vaccine-Penumocoous</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">6th Month </h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>HBA1c</span></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">12th Month </h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span>Comprehensive Medical Evaluation ( History &amp; Physical Examination)</span>
                                                        <span>Psychological Assessment</span>
                                                        <span>Vital Signs &nbsp;&amp; BMI Measurements</span>
                                                        <span>Smoking Cessation</span>
                                                        <span>Medical Nutritional Therapy</span>
                                                        <span>Foot Examination</span>
                                                        <span>Eye Examination</span>
                                                        <span>Dental Examination</span>
                                                        <span>HBA1c</span>
                                                        <span>Lipid Profile</span>
                                                        <span>Albumin/Creatinin Ratio</span>
                                                        <span>Serum Creatinin</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> 
                        }

                        {pathwayId == '2' && 
                            <div className='row'>
                                <div className='col-md-12 px-0'>
                                    <h1 className='carePlanChangeTitle'>Intermittent poorly controlled</h1>
                                </div>
                                <div className="col-md-12 mt-2 px-0">
                                    <div className="table-responsive pathways-new-table">
                                        <table className="table table-striped mb-0 mt-2">
                                            <tbody>    
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">1st Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span>Comprehensive Medical Evaluation ( History &amp; Physical Examination)</span>
                                                        <span>Vital Signs &nbsp;&amp; BMI Measurements</span>
                                                        <span>Smoking Cessation</span>
                                                        <span>Medical Nutritional Therapy</span>
                                                        <span>Foot Examination</span>
                                                        <span>Dental Examination</span>
                                                        <span>HBA1c</span>
                                                        <span>Lipid Profile</span>
                                                        <span>Albumin/Creatinin Ratio</span>
                                                        <span>Serum Creatinin</span>
                                                        <span>Vaccine - Hepatitis B</span>
                                                        <span>Vaccine-Penumocoous</span>
                                                        <span>Vaccine - Influnza</span>
                                                        <span>Eye Examination</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">3rd Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>HBA1c</span></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">6th Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span>Vital Signs &nbsp;&amp; BMI Measurements</span>
                                                        <span>Smoking Cessation</span>
                                                        <span>Medical Nutritional Therapy</span>
                                                        <span>Foot Examination</span>
                                                        <span>HBA1c</span>
                                                        <span>Lipid Profile</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">9th Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><span>HBA1c</span></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">12th Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span>Comprehensive Medical Evaluation ( History &amp; Physical Examination)</span>
                                                        <span>Vital Signs &nbsp;&amp; BMI Measurements</span>
                                                        <span>Smoking Cessation</span>
                                                        <span>Medical Nutritional Therapy</span>
                                                        <span>Foot Examination</span>
                                                        <span>Eye Examination</span>
                                                        <span>Dental Examination</span>
                                                        <span>HBA1c</span>
                                                        <span>Lipid Profile</span>
                                                        <span>Albumin/Creatinin Ratio</span>
                                                        <span>Serum Creatinin</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> 
                        }

                        {pathwayId == '3' && 
                            <div className='row'>
                                <div className='col-md-12 px-0'>
                                    <h1 className='carePlanChangeTitle'>Not well controlled</h1>
                                </div>
                                <div className="col-md-12 mt-2 px-0">
                                    <div className="table-responsive pathways-new-table">
                                        <table className="table table-striped mb-0 mt-2">
                                            <tbody>    
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">1st Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span>Comprehensive Medical Evaluation ( History &amp; Physical Examination)</span>
                                                        <span>Vital Signs &nbsp;&amp; BMI Measurements</span>
                                                        <span>Smoking Cessation</span>
                                                        <span>Medical Nutritional Therapy</span>
                                                        <span>Foot Examination</span>
                                                        <span>Dental Examination</span>
                                                        <span>HBA1c</span>
                                                        <span>Lipid Profile</span>
                                                        <span>Albumin/Creatinin Ratio</span>
                                                        <span>Serum Creatinin</span>
                                                        <span>Vaccine - Hepatitis B</span>
                                                        <span>Vaccine-Penumocoous</span>
                                                        <span>Vaccine - Influnza</span>
                                                        <span>Eye Examination</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">3rd Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span>Vital Signs &nbsp;&amp; BMI Measurements</span>
                                                        <span>Smoking Cessation</span>
                                                        <span>Medical Nutritional Therapy</span>
                                                        <span>Foot Examination</span>
                                                        <span>HBA1c</span>
                                                        <span>Lipid Profile</span>
                                                        <span>Albumin/Creatinin Ratio</span>
                                                        <span>Serum Creatinin</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">6th Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span>Vital Signs &nbsp;&amp; BMI Measurements</span>
                                                        <span>Smoking Cessation</span>
                                                        <span>Medical Nutritional Therapy</span>
                                                        <span>Foot Examination</span>
                                                        <span>HBA1c</span>
                                                        <span>Lipid Profile</span>
                                                        <span>Albumin/Creatinin Ratio</span>
                                                        <span>Serum Creatinin</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">9th Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span>Vital Signs &nbsp;&amp; BMI Measurements</span>
                                                        <span>Smoking Cessation</span>
                                                        <span>Medical Nutritional Therapy</span>
                                                        <span>Foot Examination</span>
                                                        <span>HBA1c</span>
                                                        <span>Lipid Profile</span>
                                                        <span>Albumin/Creatinin Ratio</span>
                                                        <span>Serum Creatinin</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="main-pathways-title">12th Month</h5>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <span>Comprehensive Medical Evaluation ( History &amp; Physical Examination)</span>
                                                        <span>Vital Signs &nbsp;&amp; BMI Measurements</span>
                                                        <span>Smoking Cessation</span>
                                                        <span>Medical Nutritional Therapy</span>
                                                        <span>Foot Examination</span>
                                                        <span>Eye Examination</span>
                                                        <span>Dental Examination</span>
                                                        <span>HBA1c</span>
                                                        <span>Lipid Profile</span>
                                                        <span>Albumin/Creatinin Ratio</span>
                                                        <span>Serum Creatinin</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> 
                        }
                    </div> */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CarePlanChange;
