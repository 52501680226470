import  common_axios  from "./commonAxios";

let baseURL =   common_axios.defaults.baseURL;

export const serviceFetchFailedLogs = (uidStore) => {
    var dataPass = common_axios.post( baseURL + 'rcmdashboard/insight_fail_data',{
        "uidStore" : uidStore
    });
    return dataPass;
};

export const getFileLink = () => {
    var dataPass = common_axios.get( baseURL + 'rcmdashboard/fetch_fileurl');
    return dataPass;
};

export const displayUploadedReport = (startDateNew,endDateNew) => {
    var dataPass = common_axios.post(baseURL+ 'rcmdashboard/fetch_members_logs_data', {
        "startRange" : startDateNew,
        "endRange" : endDateNew
    });
    return dataPass;
};

export const uploadFile = (uploadId) => {
    var dataPass = common_axios.post(baseURL+ 'rcmdashboard/upload_file', {
        "uploadId" : uploadId
    });
    return dataPass;
};

export const preUploadFile = (fileData) => {
    var dataPass = common_axios.post(baseURL+ 'rcmdashboard/pre_upload_file', fileData, {
    });
    return dataPass;
};

export const downloadFile = (fileType,uploadId) => {
    var dataPass = common_axios.post(baseURL+ 'rcmdashboard/download_file',{
        "fileType" : fileType,
        "uploadId" : uploadId
    });
    return dataPass;
};

export const fetch_role_data = () => {
    console.log("fetch_role_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'rcmdashboard/fetch_role_data');
    return dataPass;
};



//fetch program master data MTP
export const fetch_program_data = () => {
    console.log("fetch_program_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'rcmdashboard/fetch_program_data');
    return dataPass;
};

// add user list
export const fetch_ad_users_list = (searchString) => {
    console.log("mtp_admin_user_update_data",searchString);
    var requestBody={searchString};
    var dataPass = common_axios.post( baseURL + 'rcmdashboard/fetch_ad_users',requestBody);
    return dataPass;
};

//post data user creation rcm
export const rcm_user_creation_data = (formData) => {
    console.log("mtp_user_creation_data",formData);
    var dataPass = common_axios.post( baseURL + 'rcmdashboard/rcm_user_creation_data',formData);
    return dataPass;
};

// fetch admin user data
export const fetch_admin_user_data = () => {
    console.log("fetch_admin_user_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'rcmdashboard/fetch_admin_user_data');
    return dataPass;
};


// admin user update data
export const rcm_admin_user_update_data = (userId,formData) => {
    console.log("mtp_admin_user_update_data",formData);
    var dataPass = common_axios.post( baseURL + 'rcmdashboard/rcm_admin_user_update_data?userId='+userId,formData);
    return dataPass;
};

// fetch admin userdata 
export const rcm_fetch_admin_user_data = (userId) => {
    console.log("mtp_fetch_admin_user_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'rcmdashboard/rcm_fetch_admin_user_data?userId='+userId);
    return dataPass;
};
