import React from 'react';
import { Commingsoon } from '../../utils/CommonLibrary';
import { Link } from "react-router-dom";
function DropDownAction() {

 
return (
    <>
        <div className='DropDownAction'>
        <div class="btn-group dropend">
        <button type="button" class="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-three-dots-vertical"></i>
        </button>
        <ul class="dropdown-menu">
            <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal"  data-bs-target="#upcomingFeatureModal"><i class="bi bi-pen"></i> Edit Condition</Link></li>
            <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-plus-circle"></i> Add Pathway</Link></li>
            <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-x-circle text-danger"></i> Delete Condition</Link></li>
        </ul>
        </div>
        </div>
        <Commingsoon />
    </>
  );
}

export default DropDownAction;
