import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './Sidenavmenubar.css';
import { decruptCipherJson } from '../utils/CommonLibrary';

const Sidenavmenubar = ({ setTitle }) => {
  let accessType = "admin"; // localStorage.getItem('access');
  const [isOpen, setIsOpen] = useState(false);
  const [tabMenu, setTabMenu] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  

  const checkScreenWidth = () => {
    if (window.innerWidth <= 1920) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {

    const menuArr = localStorage.getItem('tabs') ? localStorage.getItem('tabs') : '';
    const menuArrData = decruptCipherJson(menuArr);
    setTabMenu(menuArrData);

    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);

    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div className={`sidebar ${isOpen ? '' : 'open'}`}>
      <div className="logo-details">
        <div className="logo_name mt-2">
          <div className="navbar-brand" >
            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/t0v4b/kj547l32rl.png" style={{ width: "134px" }} alt="" />
          </div>
        </div>
        <i className='bx bx-menu' id="btn" onClick={toggleSidebar}></i>
      </div>
      <ul className="nav-list mt-3">
        {tabMenu ?
          tabMenu.map((item, index) => (
            <li key={`menu_${index}`}>
              <Link to={item.path}  className={location.pathname === item.path ? 'active' : ''}>
                <i className={item.icon}></i>
                <span className="links_name">{item.label}</span>
              </Link>
              <span className="tooltip">{item.label}</span>
            </li>
          )) 
          : null
        }

        {/* <li className="profile">
          <div onClick={(e) => {
            e.preventDefault();
            navigate('/logout');
          }} style={{ cursor: "pointer" }}>
            <i className='bx bx-power-off' id="log_out"></i>
            <div className="profile-details">
              <div className="name_job">
                <div className="name">Log out</div>
              </div>
            </div>
          </div>
        </li> */}
      </ul>
    </div>
  );
}

export default Sidenavmenubar;
