import React, { useState, useRef, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import './HealthManagement.css';
import Assessment from '../assessment/Assessment';
import Appointments from '../appointments/Appointments';
import HRABasic from '../hra/HRABasic';
import HRASmoking from '../hra/HRASmoking';
import AssessmentV2 from '../assessment/AssessmentV2';


const HealthManagement = ({ memberBasicInfo, showHRAModal, handleShowHRAModal, handleCloseHRAModal, getMemberBasicInfo, callServiceEventHistory }) => {
    return (
        <>
            <Modal id="wpnb1" className='HealthManagement' show={showHRAModal} onHide={handleCloseHRAModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>{memberBasicInfo.memberName}</h1>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="Assessment" id="uncontrolled-tab-example">
                        {
                            memberBasicInfo.readonly === "N" &&
                            <Tab eventKey="Assessment" title="Assessment">
                                {/*<Assessment memberBasicInfo={memberBasicInfo} />*/}
                                <AssessmentV2 memberBasicInfo={memberBasicInfo} />
                            </Tab>
                        }
                        {
                            memberBasicInfo.readonly === "N" &&
                            <Tab eventKey="healthManagement" title="HRA">
                                <HRABasic getMemberBasicInfo={getMemberBasicInfo} />
                            </Tab>
                        }
                        <Tab eventKey="Boot Appointment" title="Book Appointment">
                            <Appointments memberBasicInfo={memberBasicInfo} callServiceEventHistory={callServiceEventHistory} />
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default HealthManagement