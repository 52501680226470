import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import { bookMemberConditionServices, fetchLabs, fetchMemberConditionServices, updateMemberConditionServicesStatus, fetchServiceCategories, fetchServiceByCategory, addMemberConditionServices, fetchServiceNotes, fetchReportTypes, services_action } from "../../components/services/Service";
import './Appointments.css';
import DatePicker from 'react-datepicker';
import Moment from 'react-moment';
import moment from 'moment';
import { Link } from 'react-router-dom';


const Appointments = ({ memberBasicInfo, callServiceEventHistory }) => {
  const qsParams = useParams();
  const userId = qsParams.memberId;

  const selectedRecordObject = { recordId: "", serviceName: "", serviceType: "" }
  const bookingTMFormObject = {
    date: new Date(),
    time: "",
    doctorName: "",
    notes: ""
  }
  const bookingLTFormObject = {
    date: new Date(),
    time: "",
    labName: "",
    notes: ""
  }

  const bookingStatusUpdateObject = {
    status: "",
    notes: "",
    files: [{
      id: new Date(),
      title: "",
      reportTypeId: "",
      file: null,
      originalName: ""
    }]
  }

  const addServiceObject = {
    conditionId: (memberBasicInfo.memberPathways && memberBasicInfo.memberPathways.length > 0) ? memberBasicInfo.memberPathways[0].conditionId : "",
    pathwayId: (memberBasicInfo.memberPathways && memberBasicInfo.memberPathways.length > 0) ? memberBasicInfo.memberPathways[0].pathwayId : "",
    categoryId: '',
    serviceId: '',
    date: new Date(),
    time: null,
    notes: ""
  }
  const [bookingTMFormData, setBookingTMFormData] = useState(bookingTMFormObject);
  const [bookingLTFormData, setBookingLTFormData] = useState(bookingLTFormObject);
  const [addServiceData, setAddServiceData] = useState(addServiceObject);
  const [reportTypes, setReportTypes] = useState([]);
  const [bookingStatusUpdateFormData, setBookingStatusUpdateFormData] = useState(bookingStatusUpdateObject);
  const [serviceNotes, setServiceNotes] = useState([]);
  const [showServiceNotesModal, setShowServiceNotesModal] = useState(false);

  const getServiceNotes = async (userId, recordId, serviceType) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchServiceNotes(userId, recordId, serviceType, ct)
      .then((response) => {
        if (response.data.code === 200) {
          setServiceNotes(response.data.data.info);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const handleServiceNotes = (userId, recordId, serviceType) => {
    setServiceNotes([]);
    getServiceNotes(userId, recordId, serviceType);
  }

  const now = new Date();
  const isTodayTM = bookingTMFormData.date instanceof Date && (bookingTMFormData.date).toDateString() === now.toDateString();
  const minTimeTM = isTodayTM ? now : new Date().setHours(0, 0, 0, 0);

  const isTodayLT = bookingLTFormData.date instanceof Date && (bookingLTFormData.date).toDateString() === now.toDateString();
  const minTimeLT = isTodayLT ? now : new Date().setHours(0, 0, 0, 0);

  const isTodayAS = addServiceData.date instanceof Date && (addServiceData.date).toDateString() === now.toDateString();
  const minTimeAS = isTodayAS ? now : new Date().setHours(0, 0, 0, 0);

  const handleBookingChange = (e) => {
    const { name, value } = e.target;
    if (selectedServiceRecord.serviceType == "1") {
      setBookingTMFormData({
        ...bookingTMFormData,
        [name]: value
      });
    } else {
      setBookingLTFormData({
        ...bookingLTFormData,
        [name]: value
      });
    }
  }

  const handleBookingUpdateChange = (e) => {
    const { name, value } = e.target;
    setBookingStatusUpdateFormData({
      ...bookingStatusUpdateFormData,
      [name]: value
    });
  }

  const handleFileChange = (event, id) => {
    const file = event.target.files[0];
    const originalName = file.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        setBookingStatusUpdateFormData(prevState => ({
          ...prevState,
          files: prevState.files.map(fileInput =>
            fileInput.id === id ? { ...fileInput, file: fileData, originalName: originalName } : fileInput
          )
        }));
      }
      reader.readAsDataURL(file);
    }
  };

  const handleTitleChange = (event, id) => {
    const name = event.target.name;
    if (name === "title") {
      const title = event.target.value;
      setBookingStatusUpdateFormData(prevState => ({
        ...prevState,
        files: prevState.files.map(fileInput =>
          fileInput.id === id ? { ...fileInput, title } : fileInput
        )
      }));
    } else if (name === "reportTypeId") {
      const reportTypeId = event.target.value;
      setBookingStatusUpdateFormData(prevState => ({
        ...prevState,
        files: prevState.files.map(fileInput =>
          fileInput.id === id ? { ...fileInput, reportTypeId } : fileInput
        )
      }));
    }
  };

  const addFile = () => {
    setBookingStatusUpdateFormData(prevState => ({
      ...prevState,
      files: [...prevState.files, { id: Date.now(), title: "", file: null, originalName: "" }]
    }));
  };

  const removeFile = (id) => {
    setBookingStatusUpdateFormData(prevState => ({
      ...prevState,
      files: prevState.files.filter(fileInput => fileInput.id !== id)
    }));
  };


  const handleAddServiceChange = (e) => {
    const { name, value } = e.target;
    if (name == "categoryId") {
      getServiceByCategory(value);
      setAddServiceData({
        ...addServiceData,
        [name]: value,
        ['serviceId']: ""
      });
    } else {
      setAddServiceData({
        ...addServiceData,
        [name]: value
      });
    }

  }


  const [memberConditionServices, setMemberConditionServices] = useState([]);
  const [labs, setLabs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedServiceRecord, setSelectedServiceRecord] = useState(selectedRecordObject);
  const [showBookMemberConditionServiceModal, setShowBookMemberConditionServiceModal] = useState(false);
  const [showUpdateMemberConditionServiceModal, setShowUpdateMemberConditionServiceModal] = useState(false);
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);
  const [mcsBookingMessage, setMcsBookingMessage] = useState("");
  const [mcsBookingError, setMcsBookingError] = useState("");
  const [addServiceError, setAddServiceError] = useState("");
  const [addServiceMessage, setAddServiceMessage] = useState("");

  const [mcsUpdateMessage, setMcsUpdateMessage] = useState("");
  const [mcsUpdateError, setMcsUpdateError] = useState("");

  useEffect(() => {
    getMemberConditionServices(userId);
    getServiceCategories();
  }, [userId]);

  const getMemberConditionServices = async (userId) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchMemberConditionServices(userId, ct)
      .then((response) => {
        if (response.data.code === 200) {
          setMemberConditionServices(response.data.data.services);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const getLabs = async () => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchLabs(userId, ct)
      .then((response) => {
        if (response.data.code === 200) {
          setLabs(response.data.data.labs);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const getServiceCategories = async () => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchServiceCategories(ct)
      .then((response) => {
        if (response.data.code === 200) {
          setCategories(response.data.data.categories);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const getServiceByCategory = async (categoryId) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchServiceByCategory(categoryId, ct)
      .then((response) => {
        if (response.data.code === 200) {
          setServices(response.data.data.services);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const getReportTypes = async () => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchReportTypes(ct)
      .then((response) => {
        if (response.data.code === 200) {
          setReportTypes(response.data.data.reportTypes);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const bookMemberConditionService = async () => {
    setMcsBookingError("");
    setMcsBookingMessage("");
    let error = "";
    let fd = {};
    if (selectedServiceRecord.serviceType == "1") {
      fd = bookingTMFormData;
      const formattedDate = moment(bookingTMFormData.date).format('YYYY-MM-DD');
      const formattedTime = (bookingTMFormData.time !== "") ? moment(bookingTMFormData.time).format('HH:mm:ss') : "";
      fd['date'] = formattedDate;
      fd['time'] = formattedTime;
      if (bookingTMFormData.date === "" || bookingTMFormData.date === undefined) {
        error = "Please select preferred date";
      } else if (bookingTMFormData.time === "" || bookingTMFormData.time === undefined) {
        error = "Please select preferred time";
      } else if (bookingTMFormData.doctorName === "") {
        error = "Please enter doctor name";
      }
    } else {
      fd = bookingLTFormData;
      console.log(bookingLTFormData);
      const formattedDate = moment(bookingLTFormData.date).format('YYYY-MM-DD');
      const formattedTime = (bookingLTFormData.time !== "") ? moment(bookingLTFormData.time).format('HH:mm:ss') : "";

      fd['date'] = formattedDate;
      fd['time'] = formattedTime;
      if (bookingLTFormData.labName === "" || bookingLTFormData.labName === undefined) {
        error = "Please enter lab name";
      } else if (bookingLTFormData.date === "" || bookingLTFormData.date === undefined) {
        error = "Please select preferred date";
      } else if (bookingLTFormData.time === "" || bookingLTFormData.time === undefined) {
        error = "Please select preferred time";
      }
    }

    if (error === "") {
      const request = axios.CancelToken.source();
      let ct = {
        cancelToken: request.token
      }
      await bookMemberConditionServices(userId, selectedServiceRecord.recordId, fd, selectedServiceRecord.serviceType, ct)
        .then((response) => {
          if (response.data.code === 200) {
            if (selectedServiceRecord.serviceType == "1") {
              setBookingTMFormData(bookingTMFormObject)
            } else {
              setBookingLTFormData(bookingLTFormObject)
            }
            setMcsBookingMessage(response.data.data.message)
            setShowBookMemberConditionServiceModal(false);
            getMemberConditionServices(userId);
          } else {
            setMcsBookingError(response.data.data.message)
          }
        }).catch(function (error) {
          console.log(error);
        });
      return () => request.cancel();
    } else {
      setMcsBookingError(error);
    }
  }

  const addNewMemberConditionService = async () => {
    setAddServiceError("");
    setAddServiceMessage("");
    let error = "";
    let fd = {};
    fd = structuredClone(addServiceData);
    const formattedDate = moment(addServiceData.date).format('YYYY-MM-DD');
    const formattedTime = (moment(addServiceData.time).isValid()) ? moment(addServiceData.time).format('HH:mm:ss') : "";
    fd['date'] = formattedDate;
    fd['time'] = formattedTime;
    if (addServiceData.categoryId === "") {
      error = "Please select the category";
    } else if (addServiceData.serviceId === "") {
      error = "Please select the service";
    } else if (addServiceData.date === "" || addServiceData.date === undefined) {
      error = "Please select preferred date";
    } else if (addServiceData.time === "" || addServiceData.time === undefined) {
      error = "Please select preferred time";
    }

    if (error === "") {
      const request = axios.CancelToken.source();
      let ct = {
        cancelToken: request.token
      }
      await addMemberConditionServices(userId, fd, ct)
        .then((response) => {
          if (response.data.code === 200) {
            setAddServiceData(addServiceObject)
            setAddServiceMessage(response.data.data.message)
            handleAddServiceModal();
            getMemberConditionServices(userId);
            setServices([]);
          } else {
            setAddServiceError(response.data.data.message)
          }
        }).catch(function (error) {
          console.log(error);
        });
      return () => request.cancel();
    } else {
      setAddServiceError(error);
    }
  }

  const updateMemberConditionService = async () => {
    setMcsBookingError("");
    setMcsBookingMessage("");
    let error = "";
    if (bookingStatusUpdateFormData.status === "" || bookingStatusUpdateFormData.status === undefined) {
      error = "Please select the status";
    } else {
      for (let index = 0; index < bookingStatusUpdateFormData.files.length; index++) {
        const fileInput = bookingStatusUpdateFormData.files[index];
        if (fileInput.file !== null && fileInput.reportTypeId === "") {
          error = "Please select the report type";
          break;
        }
      }
    }


    if (error === "") {
      const request = axios.CancelToken.source();
      let ct = {
        cancelToken: request.token
      }
      await updateMemberConditionServicesStatus(userId, selectedServiceRecord.recordId, bookingStatusUpdateFormData, selectedServiceRecord.serviceType, ct)
        .then((response) => {
          if (response.data.code === 200) {
            setMcsUpdateMessage(response.data.data.message)
            setShowUpdateMemberConditionServiceModal(false);
            getMemberConditionServices(userId);
          } else {
            setMcsUpdateError(response.data.data.message)
          }
        }).catch(function (error) {
          console.log(error);
        });
      return () => request.cancel();
    } else {
      setMcsUpdateError(error);
    }
  }

  const handleAddServiceModal = () => {
    setShowAddServiceModal(!showAddServiceModal);
  }

  const handleTimeChange = (date) => {
    if (date && moment(date).isValid()) {
      setAddServiceData({
        ...addServiceData,
        time: date
      });
    } else {
      setAddServiceData({
        ...addServiceData,
        time: null
      });
    }
  };

  async function changeConditionalServiceAction(e, service, index, mainIndex) {
    const newStatus = e.target.value;

    setMemberConditionServices((prevMemberServices) => {
      // Create a copy of the previous state to avoid mutation
      const updatedMemberServices = [...prevMemberServices];

      updatedMemberServices[mainIndex] = {
        ...updatedMemberServices[mainIndex],
        services: updatedMemberServices[mainIndex].services.map((s, idx) => {
          if (idx === index) {
            return {
              ...s,
              bookingStatus: newStatus,
            };
          }
          return s;
        }),
      };

      return updatedMemberServices;
    });

    try {
      await services_action({ serviceId: service.recordId, serviceType: "conditional", status: newStatus });
      callServiceEventHistory(userId)
    } catch (error) {

    }
  }

  return (
    <>
      <div className='col-md-12'>
        <div className='card card-task-appoint'>
          <div className='card-body'>
            <div className='card-text'>
              {
                memberConditionServices.map((mainItem, mainIndex) => {
                  return <>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h5 className='card-title'>{mainItem.programName} / {mainItem.conditionName}</h5>
                      </div>
                      <div className='col-auto'>
                        {
                          memberBasicInfo.readonly === "N" &&
                          <button className="btn btn-primary addServices-btn" onClick={handleAddServiceModal}>Add Services <i className="bi bi-plus-lg"></i></button>
                        }

                      </div>
                      <div>
                        <div className="table-responsive mt-2">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Services</th>
                                <th scope="col">Due Date</th>
                                <th scope="col">Action</th>
                                {/*<th scope="col">Notes</th>*/}
                              </tr>
                            </thead>
                            <tbody>
                              {
                                (mainItem.services) ?
                                  mainItem.services.map((item, index) => {
                                    return <>
                                      <tr style={{ verticalAlign: 'middle' }}>
                                        <td className='bg-td-green'><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/43t1m/vat93u8oj1.png" style={{ height: "20px" }} className='me-2' alt="" />{item.serviceName}</td>
                                        <td>{item.dueDate}</td>
                                        <td>
                                          <select disabled={(memberBasicInfo.readonly === "Y") ? true : false} class="service-action"
                                            value={item.bookingStatus}
                                            onChange={e => changeConditionalServiceAction(e, item, index, mainIndex)}
                                          >
                                            <option value="due">-</option>
                                            <option value="booked">Booked</option>
                                            <option value="scheduled">Scheduled</option>
                                            <option value="cancelled">Cancelled</option>
                                            <option value="postponed">Postponed</option>
                                            <option value="completed">Completed</option>
                                            <option value="no-answer">No-answer</option>
                                            <option value="notrequired">Not required</option>
                                            <option value="completedbyotherprovider">Completed By Other Provider</option>
                                          </select>
                                        </td>
                                        <td style={{ display: "none" }}>
                                          {
                                            (item.bookingStatus === "due") ?
                                              <button class="btn btn-success book-now-btn" onClick={() => {
                                                setSelectedServiceRecord({ recordId: item.recordId, serviceName: item.serviceName, serviceType: item.serviceType });
                                                setShowBookMemberConditionServiceModal(true);
                                                (item.serviceType == "1") ? setBookingTMFormData(bookingTMFormObject) : setBookingLTFormData(bookingLTFormObject);
                                                getLabs();
                                                setMcsBookingError("")
                                                setMcsBookingMessage("");
                                              }}>Book</button> :
                                              (item.bookingStatus === "scheduled") ?
                                                <button class="btn btn-info" onClick={() => {
                                                  setSelectedServiceRecord({ recordId: item.recordId, serviceName: item.serviceName, serviceType: item.serviceType });
                                                  getReportTypes();
                                                  setShowUpdateMemberConditionServiceModal(true);
                                                  setBookingStatusUpdateFormData(bookingStatusUpdateObject)
                                                  setMcsUpdateError("");
                                                  setMcsUpdateMessage("");
                                                }}>Scheduled</button> :
                                                (item.bookingStatus === "completed") ?
                                                  <button className="btn btn-primary complated-btn">Completed</button> :
                                                  <button className="btn btn-danger">Missed</button>
                                          }
                                        </td >
                                        {/*<td>
                                          {
                                            (item.bookingStatus != "due") ?
                                              <button class="btn btn-primary viewNotes-btn" onClick={() => {
                                                setSelectedServiceRecord({ recordId: item.recordId, serviceName: item.serviceName, serviceType: "conditional" });
                                                handleServiceNotes(userId, item.recordId, "conditional");
                                                setShowServiceNotesModal(true);
                                              }}>View Notes</button>
                                              : ""
                                          }
                                        </td>*/}
                                      </tr >
                                    </>
                                  }) : ""
                              }
                            </tbody>
                          </table>
                        </div>
                      </div >
                    </div >
                  </>
                })
              }

            </div>
          </div>
        </div >
      </div >
      <Modal id="bookingModal" show={showBookMemberConditionServiceModal} onHide={() => setShowBookMemberConditionServiceModal(false)} size="" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title'>{selectedServiceRecord.serviceName}</h1>
        </Modal.Header>
        <Modal.Body>
          <div className={`${mcsBookingError != "" ? "errorDiv" : "hide"}`}>{mcsBookingError}</div>

          {
            (selectedServiceRecord.serviceType == "3") &&
            <>
              <Row>
                <Col md={12} className='mb-3'>
                  <label className='form-label'>Select Lab <span className='required'>*</span></label>
                  <select className='form-select control-input-ui' name="labName" value={bookingLTFormData.labName} onChange={handleBookingChange}>
                    <option value="">Select</option>
                    {
                      labs.map((item, index) => {
                        return <option value={item.labName}>{item.labName}</option>
                      })
                    }
                  </select>
                </Col>
              </Row>
              <Row>
                <Col md={12} className='mb-3'>
                  <label className='form-label'>Preferred Date <span className='required'>*</span></label>
                  <DatePicker
                    selected={bookingLTFormData.date}
                    onChange={(date) => setBookingLTFormData({
                      ...bookingLTFormData,
                      date: date
                    })}
                    dateFormat="dd-MM-yyyy"
                    placeholderText=""
                    minDate={new Date()}
                    className='form-control control-input-ui'
                    onKeyDown={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className='mb-3'>
                  <label className='form-label'>Preferred Time <span className='required'>*</span></label>
                  <DatePicker
                    selected={bookingLTFormData.time}
                    onChange={(date) => setBookingLTFormData({
                      ...bookingLTFormData,
                      time: date
                    })}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15} // Set time intervals, e.g., 15 minutes
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    minTime={minTimeLT}
                    maxTime={new Date().setHours(23, 59)}
                    className='form-control control-input-ui'
                    onKeyDown={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className='mb-3'>
                  <label className='form-label'>Remarks</label>
                  <textarea name="notes" className='form-control control-input-ui' onChange={handleBookingChange}>{bookingLTFormData.notes}</textarea>
                </Col>
              </Row>
              <Row>
                <Col className='text-center'>
                  <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={() => bookMemberConditionService()}>Book</Button>
                </Col>
              </Row>
            </>
          }

          {
            (selectedServiceRecord.serviceType == "1") &&
            <>
              <Row>
                <Col md={12} className='mb-3'>
                  <label className='form-label'>Preferred Date <span className='required'>*</span></label>
                  <DatePicker
                    selected={bookingTMFormData.date}
                    onChange={(date) => setBookingTMFormData({
                      ...bookingTMFormData,
                      date: date
                    })}
                    dateFormat="dd-MM-yyyy"
                    placeholderText=""
                    minDate={new Date()}
                    className='form-control control-input-ui'
                    onKeyDown={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className='mb-3'>
                  <label className='form-label'>Preferred Time <span className='required'>*</span></label>
                  <DatePicker
                    selected={bookingTMFormData.time}
                    onChange={(date) => setBookingTMFormData({
                      ...bookingTMFormData,
                      time: date
                    })}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15} // Set time intervals, e.g., 15 minutes
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    minTime={minTimeLT}
                    maxTime={new Date().setHours(23, 59)}
                    className='form-control control-input-ui'
                    onKeyDown={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className='mb-3'>
                  <label className='form-label'>Doctor Name <span className='required'>*</span></label>
                  <input type="text" className='form-control control-input-ui' name="doctorName" value={bookingTMFormData.doctorName} onChange={handleBookingChange} />
                </Col>
              </Row>

              <Row>
                <Col md={12} className='mb-3'>
                  <label className='form-label'>Remarks</label>
                  <textarea name="notes" className='form-control control-input-ui' onChange={handleBookingChange}>{bookingTMFormData.notes}</textarea>
                </Col>
              </Row>
              <Row>
                <Col className='text-center'>
                  <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={() => bookMemberConditionService()}>Book</Button>
                </Col>
              </Row>
            </>
          }
        </Modal.Body>
      </Modal>

      <Modal id="bookingStatusUpdateModal" show={showUpdateMemberConditionServiceModal} onHide={() => setShowUpdateMemberConditionServiceModal(false)} size="lg" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title'>{selectedServiceRecord.serviceName}</h1>
        </Modal.Header>
        <Modal.Body>
          <div className={`${mcsUpdateError != "" ? "errorDiv" : "hide"}`}>{mcsUpdateError}</div>
          <Row>
            <Col md={12} className='mb-3'>
              <label className='form-label'>Select Status <span className='required'>*</span></label>
              <select className='form-select control-input-ui' name="status" value={bookingStatusUpdateFormData.status} onChange={handleBookingUpdateChange}>
                <option value="">Select</option>
                <option value="missed">Missed</option>
                <option value="completed">Completed</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col md={12} className='mb-3'>
              <label className='form-label'>Remarks</label>
              <textarea name="notes" className='form-control control-input-ui' onChange={handleBookingUpdateChange}>{bookingStatusUpdateFormData.notes}</textarea>
            </Col>
          </Row>
          <Row>
            <Col md={12} className='mb-3'>
              <label>Attachments </label>
              <i className="fa fa-plus-square add-file" aria-hidden="true" onClick={addFile}></i>
              {bookingStatusUpdateFormData.files.map((item, index) => (
                <div key={item.id}>
                  <Row>
                    <Col md={4}>
                      <input type="text" value={item.title} name="title" className='form-control control-input-ui' onChange={(event) => handleTitleChange(event, item.id)} />
                    </Col>
                    <Col>
                      <select name="reportTypeId" className="form-select select-input-ui" value={item.reportTypeId} onChange={(event) => handleTitleChange(event, item.id)}>
                        <option value="">Select</option>
                        {
                          reportTypes.map((rtItem, index) => {
                            return <option value={rtItem.reportTypeId}>{rtItem.reportType}</option>
                          })
                        }
                      </select>
                    </Col>
                    <Col>
                      <input
                        type="file"
                        accept=".pdf,.jpeg,.jpg,.png,.tiff,.doc,.docx,.xls,.xlsx,.csv,.txt,.dcm"
                        className='file-control control-input-ui'
                        onChange={(event) => handleFileChange(event, item.id)}
                      /> <i className="fa fa-trash" aria-hidden="true" onClick={() => removeFile(item.id)}></i>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={() => updateMemberConditionService()}>Update</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal id="addServiceModal" show={showAddServiceModal} onHide={handleAddServiceModal} size="" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title'>Add New Service</h1>
        </Modal.Header>
        <Modal.Body>
          <div className={`${addServiceError != "" ? "errorDiv" : "hide"}`}>{addServiceError}</div>
          <>
            <Row>
              <Col md={12} className='mb-3'>
                <label className='form-label'>Select Category <span className='required'>*</span></label>
                <select className='form-select control-input-ui' name="categoryId" value={addServiceData.categoryId} onChange={handleAddServiceChange}>
                  <option value="">Select</option>
                  {
                    categories.map((item, index) => {
                      return <option value={item.categoryId}>{item.categoryName}</option>
                    })
                  }
                </select>
              </Col>
            </Row>
            <Row>
              <Col md={12} className='mb-3'>
                <label className='form-label'>Select Service <span className='required'>*</span></label>
                <select className='form-select control-input-ui' name="serviceId" value={addServiceData.serviceId} onChange={handleAddServiceChange}>
                  <option value="">Select</option>
                  {
                    services.map((item, index) => {
                      return <option value={item.serviceId}>{item.serviceName}</option>
                    })
                  }
                </select>
              </Col>
            </Row>
            <Row>
              <Col md={12} className='mb-3'>
                <label className='form-label'>Preferred Date <span className='required'>*</span></label>
                <DatePicker
                  selected={addServiceData.date}
                  onChange={(date) => setAddServiceData({
                    ...addServiceData,
                    date: date
                  })}
                  dateFormat="dd-MM-yyyy"
                  placeholderText=""
                  minDate={new Date()}
                  className='form-control control-input-ui'
                  onKeyDown={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className='mb-3'>
                <label className='form-label'>Preferred Time <span className='required'>*</span></label>
                <DatePicker
                  selected={addServiceData.time}
                  onChange={(date) => setAddServiceData({
                    ...addServiceData,
                    time: date
                  })}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  minTime={minTimeAS}
                  maxTime={new Date().setHours(23, 59)}
                  className='form-control control-input-ui'
                  onKeyDown={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className='mb-3'>
                <label className='form-label'>Remarks</label>
                <textarea name="notes" className='form-control control-input-ui' onChange={handleAddServiceChange} value={addServiceData.notes}></textarea>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={() => addNewMemberConditionService()}>Add</Button>
              </Col>
            </Row>
          </>

        </Modal.Body>
      </Modal>
      <Modal id="serviceNotes" show={showServiceNotesModal} centered onHide={() => setShowServiceNotesModal(false)} size="md" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title' style={{ width: "90%" }}>{selectedServiceRecord.serviceName}</h1>
        </Modal.Header>
        <Modal.Body>

          <Row>
            <Col md={12} className='mb-3'>
              <label className='form-label'>Due Date:</label>
              <span>{serviceNotes.dueDate}</span>
            </Col>
          </Row>
          <Row>
            <Col md={12} className='mb-3'>
              <label className='form-label'>Booking Date:</label>
              <span>{serviceNotes.bookingDate}</span>
            </Col>
          </Row>
          <Row>
            <Col md={12} className='mb-3'>
              <label className='form-label'>Booking Remarks:</label>
              <span>{serviceNotes.bookingNotes}</span>
            </Col>
          </Row>
          <Row>
            <Col md={12} className='mb-3'>
              <label className='form-label'>Status Update Date:</label>
              <span>{serviceNotes.statusUpdateDate}</span>
            </Col>
          </Row>
          <Row>
            <Col md={12} className='mb-3'>
              <label className='form-label'>Status Update Remarks:</label>
              <span>{serviceNotes.statusUpdateNotes}</span>
            </Col>
          </Row>
          <Row>
            <Col md={12} className='mb-3'>
              <label className='form-label'>Attachments:</label>
              {
                (serviceNotes.files) &&
                  (serviceNotes.files.length > 0) ?
                  serviceNotes.files.map((file, index) => {
                    return <>
                      <Link to={file} target="_blank"><i className="fa fa-paperclip" aria-hidden="true"></i>
                      </Link></>
                  }) : ""
              }
            </Col>
          </Row>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default Appointments