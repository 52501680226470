import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import firebase from './firebase/firebase';
import { getDatabase, ref, onValue, child, get } from "firebase/database";
import './App.css';
import Protected from './Protected';

import LoginPage from './pages/LoginPage';
import Logout from './pages/Logout';
import ValidatePage from './pages/utils/ValidatePage';
import ProgramsPage from './pages/mtp/program/ProgramsPage';
import ConfigurationListPage from './pages/componenents/ConfigurationListPage';
import UploadMemberDetailsPage from './pages/rcm/UploadMemberDetailsPage';
import ReportsPage from './pages/rcm/ReportsPage';
import ConfigurationPage from './pages/mtp/configurationpage/ConfigurationPage';
import UserManagementPage from './pages/mtp/usermanagement/UserManagementPage';
import MemberCommunicationsPage from './pages/mtp/membercommunication/MemberCommunicationsPage';
import CareNavigatorPage from './pages/CareNavigatorPage';
import ProgramPerformancePage from './pages/ProgramPerformancePage';
import CareNavigatorPerformancePage from './pages/CareNavigatorPerformancePage';
import ContentManagementPage from './pages/ContentManagementPage';
import ChallengesPage from './pages/ChallengesPage';
import ContentAddUserManagement from './pages/componenents/ContentAddUserManagement';
import AddProgramsPage from './pages/mtp/program/AddProgramsPage';
import ContentEditUserManagement from './pages/componenents/ContentEditUserManagement';
import CareAddNewUser from './pages/medengage/ContentUserManagement/CareAddNewUser';
import NoAccess from './pages/NoAccess';
import PathNotResolved from './pages/PathNotResolved';
import ContentServiceList from './pages/componenents/ContentPrograms/ContentServiceList';
import MemberEngagementPage from './pages/met/engagementpage/MemberEngagementPage';
import MemberDeEnrollmentPage from './pages/MemberDeEnrollmentPage';
import AddNewCareNavigator from './pages/componenents/ContentCareNavigator/AddNewCareNavigator';
import ContentMemberEngagementDetails from './pages/componenents/ContentMemberEngagement/ContentMemberEngagementDetails';
import ContentCallDispositionDetails from './pages/componenents/ContentMemberEngagement/ContentCallDispositionDetails';
import ContentCallHistory from './pages/componenents/ContentMemberEngagement/ContentCallHistory';
import MemberEngagmentChallengePage from './pages/met/challenges/MemberEngagmentChallengePage';
import MemberConfigurationListPage from './pages/met/configuration/MemberConfigurationListPage';
import MemberConnectAgent from './pages/met/memberconnect/MemberConnectAgent';
import MemberCommunications from './pages/met/membercommunication/MemberCommunications';
//import MemberConnectAgent from './pages/MemberConnectAgent';
import ContentCareNavigatorPerformanceList from './pages/componenents/ContentCareNavigatorPerformance/ContentCareNavigatorPerformanceList';
import MemberTransferPage from './pages/MemberTransferPage';
import MemberManagement from './pages/met/usermanagement/UserManagementPage';
import ContentAddMemberManagement from './pages/met/components/ContentAddNewUser';
import ContentEditMemberManagement from './pages/met/components/ContentEditUserMangement';
import RCMUserManagement from './pages/rcm/usermanagement/UserManagementPage';
import RCMContentMemberManagement from './pages/rcm/components/ContentAddNewUser';
import RCMContentEditMemberManagement from './pages/rcm/components/ContentEditUserManagement';
// import ContentEditMemberManagement from './pages/met/components/ContentEditUserMangement';
import AddNewServices from './pages/componenents/ContentPrograms/AddNewServices';
import MedEngageUerManagement from './pages/medengage/usermanagement/UserManagementPage';
import MedEngageContentMemberManagement from './pages/medengage/components/ContentAddNewUser';
import MedEngageEditMemberManagement from './pages/medengage/components/ContentEditUserManagement';
import ConfigurationSettingsAgentCalls from './pages/met/configurationsettings/ConfigurationSettingsAgentCalls';
import ConfigurationSettingsCareNavigatorCalls from './pages/met/configurationsettings/ConfigurationSettingsCareNavigatorCalls';
import DashobardGraphs from './pages/DashboardGraphs';
import CNGraphs from './pages/CNGraphs';
import CareNavigatorCommunications from './pages/medengage/CareNavigatorCommunications/CareNavigatorCommunications';
import MemberProgress from './pages/medengage/MemberProgress/MemberProgress';

import CareNavigatorReports from './pages/CareNavigatorReports';
import SettingDispositionAgent from './pages/met/components/SettingDispositionAgent';
import MemberRegisterLaterPage from './pages/met/registerLater/MemberRegisterLaterPage';
//cn 
import Dashboard from './pages/cn/dashboard/Dashboard';
import Members from './pages/cn/members/Members';
import { UserTabsProvider } from './pages/cn/components/common/UserTabsContext';
import Draggable from 'react-draggable';
import IncommingAudioCall from './pages/cn/components/draggableCall/IncommingAudioCall';
import CoachCallAudio from './pages/cn/components/draggableCall/CoachCallAudio';
import { decruptCipherString } from "./pages/utils/CommonLibrary";
import RCMInsight from './pages/Insight/components/InsightHome/RCMInsight';
import EndToEndDaily from './pages/Insight/components/InsightHome/EndToEndDaily';
import InsightAddNewUser from './pages/Insight/components/InsightAddNewUser/InsightAddNewUser';
import InsightUserManagementDetails from './pages/Insight/components/InsightUserManagementDetails/InsightUserManagementDetails';
import InsightEditUser from './pages/Insight/components/InsightEditUser/InsightEditUser';

import HomeScreen from './pages/RewardCentral/HomeScreen';
import LoyaltyProgram from './pages/RewardCentral/LoyaltyProgram';
import RewardPoints from './pages/RewardCentral/RewardPoints';
import CategoryMaster from './pages/RewardCentral/CategoryMaster';
import ProductMaster from './pages/RewardCentral/ProductMaster';
import ActivityManagement from './pages/RewardCentral/ActivityManagement';

import SupportTeamDashboard from './pages/SupportTeamDashboard';
import ApproversDashboard from './pages/ApproversDashboard';
import ObservationReportersDashboard from './pages/ObservationReportersDashboard';

import WhatsAppTemplates from './pages/WhatsAppTemplates/WhatsAppTemplates';
import CreateNewTemplate from './pages/WhatsAppTemplates/CreateNewTemplate';
import ContentAgentReports from './pages/met/Reports/ContentAgentReports';
import CNCalendar from './pages/medengage/cncalendar/CNCalendar';

import UploadMemberFile from './pages/RPA/UploadMemberFile';

import Notification from './pages/Notification-dashboard/Notification';
import CampaignDetails from './pages/Notification-dashboard/CampaignDetails';
import CreateCampaign from './pages/Notification-dashboard/CreateCampaign';

import CreateChallenges from './pages/CorpWellness360/CreateChallenges';
import CorporateChallenges from './pages/CorpWellness360/CorporateChallenges';
import CreateLeaderboard from './pages/CorpWellness360/CreateLeaderboard';
import ChallengeReport from './pages/CorpWellness360/ChallengeReport';



function App() {

  let id = useCnID();

  // incomming call 
  const [shouldShowIncomming, setShouldShowIncomming] = useState(false);
  const [isAcceptedCall, setIsAcceptedCall] = useState(false);
  const [inConfNumber, setInconfNumber] = useState(null);
  const [cnID, setCnID] = useState(id);
  const [audio] = useState(new Audio('./ringtone.wav'));
  const [incommingMemberName, setIncommingMemberName] = useState("");
  const [incommingAgentName, setIncommingAgentName] = useState("");
  const [incommingMemberId, setIncommingMemberId] = useState("");

  // outgoing call 
  const [showCallBlock, setShowCallBlock] = useState(false);
  const [showDisposition, setShowDisposition] = useState(false);
  const [outgoingCallMemberInfo, setOutGoingCallMemberInfo] = useState({
    userId: "",
    memberName: "",
    mobile: ""
  });

  // open outoing call window
  function openOutGoingCallWindow(info) {
    if (showCallBlock) return;

    const { mobileNo: mobile, memberName, userId } = info;
    setOutGoingCallMemberInfo({
      mobile, memberName, userId
    });
    setShowCallBlock(true);
  }

  // close the call window
  function changeCallBlockDisplayStatus(status) {
    setShowCallBlock(status);
  }

  // trigger disposition 
  function triggerDisposition() {
    setShowDisposition(true);
  }


  useEffect(() => {

    const fetchData = async () => {

      const database = getDatabase(firebase);
      // just read values from this database 
      const dbRef = ref(database);
      const realtimeRef = ref(database, `carenavigator/${cnID}`);

      // write a onchange 
      onValue(realtimeRef, (snapshot) => {
        const data = snapshot.val();

        if (data) {
          // console.log(data);
          setInconfNumber(data.conference.confNumber);
          setIncommingMemberName(data.conference.memberName);
          setIncommingAgentName(data.conference.agentName);
          setShouldShowIncomming(true);
          setIncommingMemberId(data.conference.memberId);
          // playRingtone(); 
        } else {
          setShouldShowIncomming(false);
        }

      });

      get(child(dbRef, `carenavigator/${cnID}`)).then((snapshot) => {
        if (snapshot.exists()) {
          // console.log(snapshot.val());
        } else {
          // console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }

    if (cnID) fetchData();

  }, []);

  // const notification = new Notification("Member Added to Carenavigator", { body: "This is Test Notification", icon: "https://www.hindustantimes.com/static-content/1y/cricket-logos/players/virat-kohli.png" });



  return (
    <>

      {
        showCallBlock &&
        <Draggable bounds="body">
          <div className="box-draggable">
            <CoachCallAudio userId={outgoingCallMemberInfo.userId} memberName={outgoingCallMemberInfo.memberName} changeCallBlockDisplayStatus={changeCallBlockDisplayStatus} triggerDisposition={triggerDisposition} mobileNo={outgoingCallMemberInfo.mobile} />
          </div>
        </Draggable>
      }

      {
        shouldShowIncomming &&
        <Draggable bounds="body">
          <div className="box-draggable">
            <IncommingAudioCall incommingConfNumber={inConfNumber}
              isAcceptedCall={isAcceptedCall} memberName={incommingMemberName} agentName={incommingAgentName}
              setIsAcceptedCall={setIsAcceptedCall} setShouldShowIncomming={setShouldShowIncomming} incommingMemberId={incommingMemberId}
              cnId={cnID}
            />

          </div>
        </Draggable>
      }


      <UserTabsProvider>

        <Routes>
          <Route path="/">

            {/* Starts Common */}
            <Route exact path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/validate" element={<ValidatePage />} />
            <Route path="/no-access" element={<NoAccess />} title='Invalid Request' />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<PathNotResolved/>} />
            {/* Ends Common */}

            {/* Starts DataMigrate */}
            <Route path="/upload-member-details" element={<Protected Component={UploadMemberDetailsPage} title='Upload Member Details' />} />
            <Route path="/reports" element={<Protected Component={ReportsPage} />} title='Reports Overview' />
            <Route path='/member-management-rcm' element={<Protected Component={RCMUserManagement} title='User Management' />} />
            <Route path="/add-new-user-rcm" element={<Protected Component={RCMContentMemberManagement} title='Add New Member' />} />
            <Route path='/edit-new-member-rcm/:id' element={<Protected Component={RCMContentEditMemberManagement} title='Edit New Member' />} />
            {/* Ends DataMigrate */}

            {/* Starts MediCareManage */}
            <Route path="/programs" element={<Protected Component={ProgramsPage} title='Programs' />} />
            <Route path="/configuration-list" element={<Protected Component={ConfigurationListPage} title='Configuration List' />} />
            <Route path="/configuration" element={<Protected Component={ConfigurationPage} title='Configuration' />} />
            <Route path="/user-management" element={<Protected Component={UserManagementPage} title='User Management' />} />
            <Route path="/member-communications" element={<Protected Component={MemberCommunicationsPage} title='Member Communications' />} />
            <Route path="/add-new-user" element={<Protected Component={ContentAddUserManagement} title='Add New user' />} />
            <Route path="/add-program" element={<Protected Component={AddProgramsPage} title='Add Programs' />} />
            <Route path="/add-service/:pathwayId" element={<Protected Component={ContentServiceList} title='Add Programs' />} />
            <Route path="/edit-new-user/:userId" element={<Protected Component={ContentEditUserManagement} title='Edit New user' />} />
            <Route path="/add-new-service" element={<Protected Component={AddNewServices} title='Add New Service' />} />
            {/* Ends MediCareManage */}

            {/* Starts CareCoordinate */}
            <Route path="/care-navigator" element={<Protected Component={CareNavigatorPage} title='Care Navigator Management' />} />
            <Route path="/add-care-navigator/:id?" element={<Protected Component={AddNewCareNavigator} title='Add New Navigator Management' />} />
            <Route path="/edit-care-navigator/edit/:id?" element={<Protected Component={AddNewCareNavigator} title='Edit Navigator Management' />} />
            <Route path="/program-performance" element={<Protected Component={ProgramPerformancePage} title='Population Compliance & Success' />} />
            <Route path="/care-navigator-performance" element={<Protected Component={CareNavigatorPerformancePage} title='Care Navigator Performance' />} />
            <Route path="/care-navigator-communications" element={<Protected Component={CareNavigatorCommunications} title='Care Navigator Communications' />} />
            <Route path="/care-call-details" element={<Protected Component={ContentCareNavigatorPerformanceList} title='Care Call Details' />} />
            <Route path="/content-management" element={<Protected Component={ContentManagementPage} title='Content Management' />} />
            <Route path='/cn-graphs' element={<Protected Component={CNGraphs} title='cn Graphs' />} />
            <Route path='/member-management-medengage' element={<Protected Component={MedEngageUerManagement} title='User Management' />} />
            <Route path="/add-new-user-medengage" element={<Protected Component={MedEngageContentMemberManagement} title='Add New Member' />} />
            <Route path='/edit-new-member-medengage/:id' element={<Protected Component={MedEngageEditMemberManagement} title='Edit New Member' />} />
            <Route path="/member-de-enrollment" element={<Protected Component={MemberDeEnrollmentPage} title='Member De-Enrollment' />} />
            <Route path="/member-transfer/:id?" element={<Protected Component={MemberTransferPage} title='Member Transfer' />} />
            <Route path='/care-navigator-reports' element={<Protected Component={CareNavigatorReports} title='Care Navigator Reports' />} />
            <Route path='/member-data' element={<Protected Component={MemberProgress} title='Care Navigator Reports' />} />
            <Route path='/care-navigator-appointments' element={<Protected Component={CNCalendar} title='Care Navigator Appointments' />} />
            {/* Ends CareCoordinate */}

            {/* Starts MemberConnect */}
            <Route path="/member-insight" element={<Protected Component={ContentCallHistory} title='Member Engagement (Phase 1)' />} />
            <Route path="/challenges" element={<Protected Component={ChallengesPage} title='Challenges' />} />
            <Route path="/member-engagement1" element={<Protected Component={ContentMemberEngagementDetails} title='Member Engagement Mannual Phase 1' />} />
            <Route path="/member-engagement" element={<Protected Component={MemberEngagementPage} title='Member Engagement' />} />
            <Route path="/member-configuration" element={<Protected Component={MemberConfigurationListPage} title='Member Engagement' />} />
            <Route path="/membercommunications" element={<Protected Component={MemberCommunications} title='Member Communications' />} />
            <Route path='/call-setting' element={<Protected Component={ConfigurationSettingsAgentCalls} title='Configuration Settings Agent Calls' />} />
            <Route path="/member-challenges" element={<Protected Component={MemberEngagmentChallengePage} title='Member Challenges' />} />
            <Route path="/member-call-disposition" element={<Protected Component={ContentCallDispositionDetails} title='Member Call Disposition' />} />
            <Route path="/member-call-history" element={<Protected Component={ContentCallHistory} title='Member Call History' />} />
            <Route path='/member-management' element={<Protected Component={MemberManagement} title='Member Management' />} />
            <Route path="/add-new-member" element={<Protected Component={ContentAddMemberManagement} title='Add New Member' />} />
            <Route path='/edit-new-member/:userId' element={<Protected Component={ContentEditMemberManagement} title='Edit New Member' />} />
            <Route path="/member-connect" element={<Protected Component={MemberConnectAgent} title='Member Connect Agent' />} />
            <Route path='/call-settings-care-navigator-calls' element={<Protected Component={ConfigurationSettingsCareNavigatorCalls} title='Configuration Settings for Care Navigator Calls' />} />
            <Route path='/setting-disposition-agent' element={<Protected Component={SettingDispositionAgent} title='Setting Disposition Agent' />} />
            <Route path="/register-later" element={<Protected Component={MemberRegisterLaterPage} title='Member De-Enrollment' />} />
            <Route path='/agent-reports' element={<Protected Component={ContentAgentReports} title='Agent Reports' />} />
            {/* Ends MemberConnect */}

            {/* Starts carenavigator */}
            <Route path="/dashboard" element={<Protected Component={Dashboard} title="cnDashboard" />} />
            <Route path="/members/:memberId" element={<Protected Component={Members} title="cnDashboard" childrenProps={{ openOutGoingCallWindow, showDisposition, setShowDisposition, showCallBlock }} />} />
            {/* Ends carenavigator */}

            {/* Starts Insight */}
            <Route path="/end-to-end-dashboard" element={<Protected Component={RCMInsight} title="Insight Home" />} />
            <Route path="/end-to-end-daily" element={<Protected Component={EndToEndDaily} title="Insight Home" />} />
            <Route path='/member-management-end' element={<Protected Component={InsightUserManagementDetails} title='User Management' />} />
            <Route path="/add-new-user-end" element={<Protected Component={InsightAddNewUser} title='Add New Member' />} />
            <Route path='/edit-new-member-end/:id' element={<Protected Component={InsightEditUser} title='Edit New Member' />} />
            {/* Ends Insight */}

            {/* Starts WhatsApp Templates */}
            <Route path="/whatsApp-templates" element={<Protected Component={WhatsAppTemplates} title="WhatsApp Templates" />} />
            <Route path="/create-new-template" element={<Protected Component={CreateNewTemplate} title="Create New Template" />} />
            {/* Starts WhatsApp Templates */}

            {/* Starts Reward Central System */}
            <Route path="/home-screen" element={<Protected Component={HomeScreen} title="Home Screen" />} />
            <Route path="/loyalty-program" element={<Protected Component={LoyaltyProgram} title="Loyalty Program" />} />
            <Route path="/reward-points" element={<Protected Component={RewardPoints} title="Reward Points" />} />
            <Route path="/category-master" element={<Protected Component={CategoryMaster} title="Category Master" />} />
            <Route path="/product-master" element={<Protected Component={ProductMaster} title="Product Master" />} />
            <Route path="/activity-management" element={<Protected Component={ActivityManagement} title="Activity Management" />} />
            {/* Ends Reward Central System */}

            {/* Starts Support Team Dashboard */}
            <Route path="/support-team-dashboard" element={<Protected Component={SupportTeamDashboard} title="Support Team Dashboard" />} />
            <Route path="/approvers-dashboard" element={<Protected Component={ApproversDashboard} title="Approvers Dashboard" />} />
            <Route path="/observation-reporters-dashboard" element={<Protected Component={ObservationReportersDashboard} title="Observation Reporters Dashboard" />} />
            {/* Starts Support Team Dashboard */}

            {/* Starts RPA Dashboard */}
            <Route path="/upload-member-file" element={<Protected Component={UploadMemberFile} title="Upload Member File" />} />
            {/* End RPA Dashboard */}

            {/* Starts CorpWellness360 Dashboard */}
            <Route path="/create-challenges" element={<Protected Component={CreateChallenges} title="Create Challenges" />} />
            <Route path="/corporate-challenges" element={<Protected Component={CorporateChallenges} title="Corporate Challenges" />} />
            <Route path="/create-leaderboard" element={<Protected Component={CreateLeaderboard} title="Create Leaderboard" />} />
            <Route path="/challenge-report" element={<Protected Component={ChallengeReport} title="Challenge Report" />} />
            {/* End CorpWellness360 Dashboard */}


            <Route path='/dashobard-graphs' element={<Protected Component={DashobardGraphs} title='DashobardGraphs' />} />
            {/* <Route path="/member-connect" element={<MemberConnectAgent />} /> */}
            {/* <Route path="/care-add-user" element={<Protected Component={CareAddNewUser} title='User Management'/>} /> */}

            <Route path="/notification-dashboard" element={<Protected Component={Notification} title="Notification Dashboard" />} />
            <Route path="/campaign-details/:campaignId" element={<Protected Component={CampaignDetails} title="Campaign Details" />} />
            <Route path="/create-campaign" element={<Protected Component={CreateCampaign} title="Create Campaign" />} />



          </Route>
        </Routes>
      </UserTabsProvider>
    </>
  );
}

export default App;


// custom hook for getting cnid  
export function useCnID() {
  const careNavigatorID = localStorage.getItem('cnId');
  return careNavigatorID;
}