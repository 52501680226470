import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from "../../../../../node_modules/highcharts/highcharts-3d";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import 'bootstrap/dist/css/bootstrap.css';
import { fetchPieData } from "../../../../services/cn/Service";
import './Pie.css';

import highchartsMore from 'highcharts/highcharts-more'; // Import highcharts-more module
import highchartsExporting from 'highcharts/modules/exporting'; // Import exporting module


highcharts3d(Highcharts);
highchartsMore(Highcharts);
highchartsExporting(Highcharts);

const Pie = (props) => {
  let ct;
  const [apiResult, setApiResult] = useState([]);

  const pieGraphTitle = (props.pieGraphTitle !== "") ? props.pieGraphTitle : "";
  const pieGraphType = props.pieGraphType;
  const extraParams = (props.extraParams) ? props.extraParams : "";

  useEffect(() => {
    getPieData();
  }, [pieGraphType])

  const getPieData = async () => {
    const request = axios.CancelToken.source()
    ct = {
      cancelToken: request.token
    }
    await fetchPieData(pieGraphType, extraParams, ct)
      .then((response) => {
        if (response.data) {
          setApiResult(response.data.data);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel()
  }


  const [selectedSlideIndex] = useState(0);

  const options = {
    chart: {
      type: 'pie',
      height: 340,
      options3d: {
        enabled: true,
        alpha: 70,
        beta: 0
      },
      //spacing: [0, 0, 0, 0], // remove all spacing
      backgroundColor: 'transparent', // Set background to transparent
      borderWidth: 0 // Remove border
    },
    title: {
      text: pieGraphTitle,
      align: 'left', // Corrected typo in 'left'
      style: {
        color: '#333',
        fontSize: '20px',
        fontWeight: '500',
        fontFamily: 'IBM Plex Sans, sans-serif',
      }
    },
    tooltip: {
      enabled: false // Disable tooltip
    },
    plotOptions: {
      pie: {
        //innerSize: '60%', // This makes it a donut
        borderWidth: 0, // Remove inner border
        depth: 30,
        size: 190,
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            return `<span style="font-size: 14px; font-weight: 500; color: #333; margin-bottom: 4px; display: block;white-space:pre-line;">${this.point.name}</span> <span style="background-color:${this.point.color}; display: block; padding: 2px 8px; border-radius: 24px; color: #fff; font-size: 14px; width:fit-content">${this.point.y}${selectedSlideIndex === 0 ? '%' : ' Cal'}</span>`;
          }
        },
        showInLegend: true,
        shadow: false, // Remove shadow
        states: {
          hover: {
            enabled: true // Enable hover effect
          }
        }
      }
    },
    credits: {
      enabled: false
    },
    colors: (apiResult.colors) ? apiResult.colors : [],
    series: (apiResult.series) ? apiResult.series : [],
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false // Enable exporting
    },
    navigation: {
      buttonOptions: {
        enabled: true, // Enable navigation button
        symbol: '<i className="bi bi-three-dots"></i>' // Replace icon with Bootstrap Icons
      }
    }
  };

  return (

    <div className='pie-chart pb-1'>
      {
        apiResult.series && apiResult.series[0].data.length > 0 ? (
          <Row className=''>
            <Col>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
              />
            </Col>
          </Row>
        ) : (
          <>
            <h1 className='card-title mb-0'>{pieGraphTitle}</h1>
            <div className="emty-pie my-5" style={{ height: "240px" }}>
              <h5 className="emty-title-graph">There is no data to display</h5>
            </div>
          </>
        )
      }
    </div>
  )
}

export default Pie